import { z } from 'zod';

export const userSchema = z
  .object({
    id: z.string(),
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
    email: z.string(),
    phone_number: z.string().nullable(),
    phone_number_verified_at: z.string().nullable(),
  })
  .transform((data) => ({
    id: data.id,
    firstName: data.first_name ?? undefined,
    lastName: data.last_name ?? undefined,
    email: data.email,
    phoneNumber: data.phone_number ?? undefined,
    phoneNumberVerifiedAt: data.phone_number_verified_at ?? undefined,
  }));

export type User = z.infer<typeof userSchema>;
export type UserResponse = z.input<typeof userSchema>;
