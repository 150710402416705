import cookie from 'js-cookie';

import { publicEnv } from '~/features/environment/public';

/**
 * Used to set a cookie from the client
 */

export const setClientCookie = (name: string, value: string, expires: Date | number = 1) => {
  cookie.set(name, value, {
    path: '/',
    domain: publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME === 'test' ? undefined : '.udacity.com',
    expires,
    secure: publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME === 'production',
  });
};
