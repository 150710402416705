import { getAnonymousIdCookie } from '~/features/cookies/utils/get-anonymous-id-cookie';
import { userId } from '~/features/user/utils/get-user-id';

import { publicEnv } from '../environment/public';

const anonymousId = getAnonymousIdCookie();
const buildId = publicEnv.NEXT_PUBLIC_BUILD_SHA;

const commonInfo: Record<string, string> = {
  env: publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME,
};

if (anonymousId) {
  commonInfo['anonymousId'] = anonymousId;
}

if (userId) {
  commonInfo['userId'] = userId;
}

if (buildId) {
  commonInfo['buildId'] = buildId;
}

export const logger = {
  fatal: (message: string, additionalInfo: any) => {
    console.error(message, {
      level: 'fatal',
      ...commonInfo,
      ...additionalInfo,
    });
  },
  error: (message: string, additionalInfo: any) => {
    console.error(message, {
      level: 'error',
      ...commonInfo,
      ...additionalInfo,
    });
  },
  warn: (message: string, additionalInfo: any) => {
    console.warn(message, {
      level: 'warn',
      ...commonInfo,
      ...additionalInfo,
    });
  },
  log: (message: string, additionalInfo: any) => {
    console.log(message, {
      level: 'log',
      ...commonInfo,
      ...additionalInfo,
    });
  },
  debug: (message: string, additionalInfo: any) => {
    console.debug(message, {
      level: 'debug',
      ...commonInfo,
      ...additionalInfo,
    });
  },
};
