import { publicEnv } from '~/features/environment/public';
import { queryUdacityService } from '~/utils/query-udacity-service';

import { User, userSchema } from '../models/user';

export async function getUser({ jwt }: { jwt: string }): Promise<User> {
  return await queryUdacityService({
    url: `${publicEnv.NEXT_PUBLIC_USER_URL}/me`,
    method: 'GET',
    zodSchema: userSchema,
    authorization: `Bearer ${jwt}`,
  });
}
