import { ZodSchema } from 'zod';

import { JWT_COOKIE } from '~/features/cookies/constants';
import { removeCookie } from '~/features/cookies/utils/remove-cookie';
import { logger } from '~/features/logging/logger';
import { MiddlewareError } from '~/features/middleware/errors';

export async function queryUdacityService({
  url,
  method = 'POST',
  zodSchema,
  authorization,
  body,
  preferredLanguage,
}: {
  url: string;
  method?: 'GET' | 'POST' | 'DELETE' | 'PATCH';
  zodSchema: ZodSchema | null;
  authorization?: string;
  body?: string;
  preferredLanguage?: string;
}) {
  const response = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      ...(authorization && { authorization }),
      ...(preferredLanguage && { 'Udacity-Preferred-Language': preferredLanguage }),
    },
    body: body,
  }).catch((error) => {
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      logger.fatal('CORS error fetching from Udacity service', {
        file: 'src/utils/query-udacity-service.ts',
        function: 'queryUdacityService',
        url,
        method,
        body,
        error,
      });
    } else {
      logger.fatal('Error fetching from Udacity service', {
        file: 'src/utils/query-udacity-service.ts',
        function: 'queryUdacityService',
        url,
        method,
        body,
        error,
      });
    }

    throw error;
  });

  const data = await response.json().catch(() => {});

  /**
   * There are useful for logging mock data
   * Use program nd000 and project Animal Trading Cards
   */

  // if (url.includes('/contents')) {
  //   console.log(JSON.stringify(data));
  // }

  // if (body?.includes('FetchEnrollments')) {
  //   console.log(JSON.stringify(data));
  // }

  if (!response.ok) {
    logger.fatal('Error querying udacity service.', {
      file: 'src/utils/query-udacity-service.ts',
      function: 'queryUdacityService',
      statusCode: response.status,
      dataReceived: JSON.stringify(data),
      url: url,
      body: body,
    });

    // If we get a 401 here, and the user has a JWT - it *likely* means that the JWT is invalid.
    if (response.status === 401) {
      if (typeof window !== 'undefined') {
        removeCookie(JWT_COOKIE);
        window.location.replace('/');
      } else {
        throw new MiddlewareError({ name: 'INVALID_JWT', message: 'Invalid JWT' });
      }
      return;
    }

    throw new Error('Error querying udacity service from client.');
  }

  try {
    return zodSchema ? zodSchema.parse(data) : data;
  } catch (error) {
    logger.fatal('Error parsing response from udacity service.', {
      file: 'src/utils/query-udacity-service.ts',
      function: 'queryUdacityService',
      statusCode: response.status,
      dataReceived: JSON.stringify(data),
      error: JSON.stringify(error),
      url: url,
      body: body,
    });

    throw 'Error parsing response from udacity service.';
  }
}
