import { Currency } from '../models/currency';

export function getCurrencyByCountryCode(countryCode: string | undefined): Currency | undefined {
  return countryCode ? currencyMap.get(countryCode) : undefined;
}

export const currencyMap = new Map<string, Currency>([
  ['AE', 'AED'],
  ['AT', 'EUR'],
  ['AU', 'AUD'],
  ['BE', 'EUR'],
  ['BR', 'BRL'],
  ['CA', 'CAD'],
  ['CH', 'CHF'],
  ['DE', 'EUR'],
  ['EE', 'EUR'],
  ['EG', 'EGP'],
  ['ES', 'EUR'],
  ['FR', 'EUR'],
  ['FI', 'EUR'],
  ['GB', 'GBP'],
  ['GR', 'EUR'],
  ['IE', 'EUR'],
  ['IN', 'INR'],
  ['IT', 'EUR'],
  ['LT', 'EUR'],
  ['LV', 'EUR'],
  ['NL', 'EUR'],
  ['NZ', 'NZD'],
  ['PT', 'EUR'],
  ['SA', 'SAR'],
  ['SI', 'EUR'],
  ['SK', 'EUR'],
  ['US', 'USD'],
  ['JP', 'JPY'],
  ['KR', 'KRW'],
  ['HK', 'HKD'],
  ['MA', 'MAD'],
  ['QA', 'QAR'],
  ['SG', 'SGD'],
  ['ZA', 'ZAR'],
]);
