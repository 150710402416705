import { publicEnv } from '~/features/environment/public';
import { queryUdacityService } from '~/utils/query-udacity-service';

import { UserProfile, userProfileQuery, userProfileSchema } from '../models/user-profile';

export async function getUserProfile({ jwt, userId }: { jwt: string; userId: string }): Promise<UserProfile> {
  return await queryUdacityService({
    url: publicEnv.NEXT_PUBLIC_PROFILE_URL,
    zodSchema: userProfileSchema,
    authorization: `Bearer ${jwt}`,
    body: JSON.stringify({
      query: userProfileQuery,
      variables: { uid: userId },
    }),
  });
}
