import cookie from 'js-cookie';

import { USER_TYPE_COOKIE } from '~/features/cookies/constants';

export function getUserTypeCookie(): 'consumer' | 'enterprise' | undefined {
  const userType = cookie.get(USER_TYPE_COOKIE);

  if (userType !== 'consumer' && userType !== 'enterprise') {
    return undefined;
  }

  return userType;
}
