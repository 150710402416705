import { z } from 'zod';

import { gql } from '~/utils/gql';

export const userProfileSchema = z
  .object({
    data: z.object({
      profile: z.object({
        id: z.string(),
        photoUrl: z
          .string()
          .optional()
          .nullable()
          .transform((val) => val ?? undefined),
      }),
    }),
  })
  .transform(({ data }) => data.profile);

export type UserProfile = z.infer<typeof userProfileSchema>;

export const userProfileQuery = gql`
  query FetchUserProfile($uid: String!) {
    profile(id: $uid) {
      id
      photoUrl
    }
  }
`;
