import { z } from 'zod';

import { gql } from '~/utils/gql';

export const userCatalogSchema = z
  .object({
    data: z.object({
      user: z.object({
        classroom: z
          .object({
            catalogUrl: z.string().nullable().optional(),
          })
          .nullable()
          .optional(),
      }),
    }),
  })
  .transform(({ data }) => ({
    hasEnterpriseCatalog:
      Boolean(data.user?.classroom?.catalogUrl?.includes('https://emc')) ||
      Boolean(data.user?.classroom?.catalogUrl?.includes('udacity.com/org/')),
    catalogUrl: data.user?.classroom?.catalogUrl,
  }));

export type UserCatalog = z.infer<typeof userCatalogSchema>;
export type UserCatalogResponse = z.input<typeof userCatalogSchema>;

export const userCatalogQuery = gql`
  query FetchUserCatalog($uid: String!) {
    user(id: $uid) {
      classroom {
        catalogUrl
      }
    }
  }
`;
