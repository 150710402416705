import { publicEnv } from '~/features/environment/public';
import { queryUdacityService } from '~/utils/query-udacity-service';

import { UserCatalog, userCatalogQuery, userCatalogSchema } from '../models/user-catalog';
import { getUserIdFromJwt } from '../utils/get-user-id-from-jwt';

export async function getUserCatalog(jwt: string): Promise<UserCatalog> {
  const userId = getUserIdFromJwt(jwt);

  if (!userId) {
    throw new Error('Provided JWT does not contain a user ID');
  }

  return await queryUdacityService({
    url: publicEnv.NEXT_PUBLIC_EXPERIENCE_URL,
    zodSchema: userCatalogSchema,
    authorization: `Bearer ${jwt}`,
    body: JSON.stringify({
      query: userCatalogQuery,
      variables: { uid: userId },
    }),
  });
}
