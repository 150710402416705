import cookie from 'js-cookie';

import { TEAM_LEARNER_COOKIE } from '~/features/cookies/constants';

export function getTeamLearnerCookie(): boolean | undefined {
  const teamLearnerCookieValue = cookie.get(TEAM_LEARNER_COOKIE);

  // If the cookie exists, it will be a string of 'true' or 'false'
  if (teamLearnerCookieValue && typeof teamLearnerCookieValue === 'string') {
    return teamLearnerCookieValue === 'true';
  }

  return;
}
