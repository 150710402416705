import { useEffect } from 'react';

import auth from '@udacity/ureact-hoth';

import {
  ACTIVE_SUBSCRIPTION_COOKIE,
  HAS_FOCUSED_EXPERIENCE_COOKIE,
  TEAM_LEARNER_COOKIE,
  USER_TYPE_COOKIE,
} from '~/features/cookies/constants';
import { getUserType } from '~/features/user/controllers/get-user-type';

import { getUserExperience } from '../../user/controllers/get-user-experience';
import { deleteAuthedUserCookies } from '../utils/delete-authed-user-cookies';
import { getActiveSubscriptionCookie } from '../utils/get-active-subscription-cookie';
import { getHasFocusedCookie } from '../utils/get-has-focused-cookie';
import { getTeamLearnerCookie } from '../utils/get-team-learner-cookie';
import { getUserTypeCookie } from '../utils/get-user-type-cookie';
import { setClientCookie } from '../utils/set-client-cookie';

/**
 * Checks to ensure the users cookies are up to date
 */

export function useCheckUserCookies() {
  async function checkUserCookies() {
    const jwt = auth.getJWT();
    const userActiveSubscriptionCookie = getActiveSubscriptionCookie();
    const teamLearnerCookie = getTeamLearnerCookie();
    const hasFocusedCookie = getHasFocusedCookie();
    const userTypeCookie = getUserTypeCookie();

    // user isn't logged in, they shouldn't have any of these cookies
    if (!jwt) {
      deleteAuthedUserCookies();
      return;
    }

    const { hasActiveSubscription, teams, hasFocused } = await getUserExperience(jwt);
    const userType = await getUserType();

    // user doesn't have an active subscription, but active subscription cookie is true
    if (!hasActiveSubscription && userActiveSubscriptionCookie) {
      setClientCookie(ACTIVE_SUBSCRIPTION_COOKIE, 'false');
    }

    // user has an active subscription, but active subscription cookie is false
    if (hasActiveSubscription && !userActiveSubscriptionCookie) {
      setClientCookie(ACTIVE_SUBSCRIPTION_COOKIE, 'true');
    }

    // user is a team learner, but team learner cookie is false
    if (teams && !teamLearnerCookie) {
      setClientCookie(TEAM_LEARNER_COOKIE, 'true');
    }

    // user is not a team learner, but team learner cookie is true
    if (!teams && teamLearnerCookie) {
      setClientCookie(TEAM_LEARNER_COOKIE, 'false');
    }

    // user has focused experience, but has focused cookie is false
    if (hasFocused && !hasFocusedCookie) {
      setClientCookie(HAS_FOCUSED_EXPERIENCE_COOKIE, 'true');
    }

    // user does not have focused experience, but focused cookie cookie is true
    if (!hasFocused && hasFocusedCookie) {
      setClientCookie(HAS_FOCUSED_EXPERIENCE_COOKIE, 'false');
    }

    // userTypeCookie doesn't match the user type
    if (userType && userTypeCookie !== userType) {
      setClientCookie(USER_TYPE_COOKIE, userType);
    }
  }

  useEffect(() => {
    checkUserCookies();
  }, []);
}
