import {
  ACTIVE_SUBSCRIPTION_COOKIE,
  HAS_FOCUSED_EXPERIENCE_COOKIE,
  SHOULD_ASK_WELCOME_FLOW_COOKIE,
  SHOULD_SHOW_WELCOME_MODAL_COOKIE,
  USER_TYPE_COOKIE,
} from '~/features/cookies/constants';

import { removeCookie } from './remove-cookie';

export function deleteAuthedUserCookies() {
  removeCookie(ACTIVE_SUBSCRIPTION_COOKIE);
  removeCookie(SHOULD_ASK_WELCOME_FLOW_COOKIE);
  removeCookie(SHOULD_SHOW_WELCOME_MODAL_COOKIE);
  removeCookie(HAS_FOCUSED_EXPERIENCE_COOKIE);
  removeCookie(USER_TYPE_COOKIE);
}
