import cookie from 'js-cookie';

import { HAS_FOCUSED_EXPERIENCE_COOKIE } from '~/features/cookies/constants';

export function getHasFocusedCookie(): boolean | undefined {
  const hasFocusedCookieValue = cookie.get(HAS_FOCUSED_EXPERIENCE_COOKIE);

  // If the cookie exists, it will be a string of 'true' or 'false'
  if (hasFocusedCookieValue && typeof hasFocusedCookieValue === 'string') {
    return hasFocusedCookieValue === 'true';
  }

  return;
}
