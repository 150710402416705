import cookie from 'js-cookie';

import { SHOULD_SHOW_WELCOME_MODAL_COOKIE } from '~/features/cookies/constants';

export function getShouldShowWelcomeModalCookie(): boolean | undefined {
  const shouldShowWelcomeModalCookie = cookie.get(SHOULD_SHOW_WELCOME_MODAL_COOKIE);

  // If the cookie exists, it will be a string of 'true' or 'false'
  if (shouldShowWelcomeModalCookie && typeof shouldShowWelcomeModalCookie === 'string') {
    return shouldShowWelcomeModalCookie === 'true';
  }

  return;
}
