import cookie from 'js-cookie';

import { ANONYMOUS_COOKIE } from '~/features/cookies/constants';

export const getAnonymousIdCookie = () => {
  const v = cookie.get(ANONYMOUS_COOKIE);
  // remove possible quotes from the UUID
  if (v?.length === 38) {
    return v.substring(1, 37);
  }
  return v;
};
