import { publicEnv } from '~/features/environment/public';
import { queryUdacityService } from '~/utils/query-udacity-service';

import { UserExperience, userExperienceQuery, userExperienceSchema } from '../models/user-experience';
import { getUserIdFromJwt } from '../utils/get-user-id-from-jwt';

export async function getUserExperience(jwt: string): Promise<UserExperience> {
  const userId = getUserIdFromJwt(jwt);

  if (!userId) {
    throw new Error('Provided JWT does not contain a user ID');
  }

  return await queryUdacityService({
    url: publicEnv.NEXT_PUBLIC_EXPERIENCE_URL,
    zodSchema: userExperienceSchema,
    authorization: `Bearer ${jwt}`,
    body: JSON.stringify({
      query: userExperienceQuery,
      variables: { uid: userId },
    }),
  });
}
