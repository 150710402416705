import { PropsWithChildren, useEffect, useRef } from 'react';

import { useUser } from '~/features/user/hooks/use-user';

import { identifyEvent } from '../identify-event';

export function WithIdentifyAuthedUser({ children }: PropsWithChildren<{}>) {
  const { userId } = useUser();
  const ref = useRef<boolean>(false);
  useEffect(() => {
    if (userId && !ref.current) {
      identifyEvent(userId);
      ref.current = true;
    }
  }, [userId]);
  return <>{children}</>;
}
