import { sleep } from '~/utils/sleep';

import { publicEnv } from '../environment/public';

import { getDefaultAnalyticProperties, getGeoAnalyticsProperties } from './utils/get-analytics-properties';

const isEventLoggerOn =
  publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'production' && publicEnv.NEXT_PUBLIC_DEBUG_ANALYTICS;

export function identifyEvent(
  userId?: string,
  traits?: Record<string, any>,
  { sendToSalesforce = false }: { sendToSalesforce?: boolean } = {},
  callback?: () => void
) {
  if (typeof window === 'undefined' || !window.analytics) return;

  if (isEventLoggerOn) {
    console.log(`identify:`, { userId, traits, sendToSalesforce });
  }

  const properties = {
    ...getDefaultAnalyticProperties(),
    ...getGeoAnalyticsProperties(),
    ...traits,
  };

  const integrations = {
    integrations: {
      Salesforce: sendToSalesforce,
    },
  };

  async function delayedCallback() {
    // Note: this is a bit hacky but we need to add a delay to ensure that the identify call has plenty of time to make its way to Marketo before the track event does.
    // TODO: figure out a better way to do this.
    await sleep(500);
    callback?.();
  }

  if (userId) {
    return window.analytics.identify(userId, properties, integrations, delayedCallback);
  }

  return window.analytics.identify(properties, integrations, delayedCallback);
}
