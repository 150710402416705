import { useQuery } from 'react-query';

import auth from '@udacity/ureact-hoth';

import { getUserTypeCookie } from '~/features/cookies/utils/get-user-type-cookie';

import { getUser } from '../controllers/get-user';
import { getUserCatalog } from '../controllers/get-user-catalog';
import { getUserPreferences } from '../controllers/get-user-preferences';
import { getUserProfile } from '../controllers/get-user-profile';

export function useUser() {
  const jwt = auth.getJWT();
  const userId = auth.getCurrentUserId();
  const userType = getUserTypeCookie();

  const isUserAuthenticated = Boolean(jwt) && Boolean(userId);

  const {
    data: userProfileData,
    error: userProfileError,
    isLoading: userProfileIsLoading,
    refetch: refetchUserProfile,
  } = useQuery('userProfile', () => getUserProfile({ jwt: jwt!, userId: userId! }), {
    enabled: isUserAuthenticated,
  });

  const {
    data: user,
    error: userError,
    isLoading: userIsLoading,
    refetch: refetchUser,
  } = useQuery('user', () => getUser({ jwt: jwt! }), {
    enabled: isUserAuthenticated,
  });

  const {
    data: userPreferences,
    error: userPreferencesError,
    isLoading: userPreferencesIsLoading,
    refetch: refetchUserPreferences,
  } = useQuery('userPreferences', () => getUserPreferences({ jwt: jwt! }), {
    enabled: isUserAuthenticated,
  });

  const {
    data: userCatalog,
    error: userCatalogError,
    isLoading: userCatalogIsLoading,
  } = useQuery('userCatalog', () => getUserCatalog(jwt!), {
    enabled: isUserAuthenticated,
  });

  return {
    userType,
    jwt,
    userId,
    isUserAuthenticated,
    user,
    userError,
    userIsLoading,
    userProfileData,
    userProfileError,
    userProfileIsLoading,
    userPreferences,
    userPreferencesError,
    userPreferencesIsLoading,
    userCatalog,
    userCatalogError,
    userCatalogIsLoading,
    refetchUser,
    refetchUserProfile,
    refetchUserPreferences,
  };
}
