import { publicEnv } from '~/features/environment/public';
import { queryUdacityService } from '~/utils/query-udacity-service';

import { UserPreferences, userPreferencesSchema } from '../models/user-preferences';

export async function getUserPreferences({ jwt }: { jwt: string }): Promise<UserPreferences | undefined> {
  return await queryUdacityService({
    url: `${publicEnv.NEXT_PUBLIC_USER_URL}/me/preferences`,
    zodSchema: userPreferencesSchema,
    method: 'GET',
    authorization: `Bearer ${jwt}`,
  });
}
