import cookie from 'js-cookie';

import { SHOULD_ASK_WELCOME_FLOW_COOKIE } from '~/features/cookies/constants';

export function getShouldAskWelcomeFlowCookie(): boolean | undefined {
  const shouldAskWelcomeFlowCookie = cookie.get(SHOULD_ASK_WELCOME_FLOW_COOKIE);

  // If the cookie exists, it will be a string of 'true' or 'false'
  if (shouldAskWelcomeFlowCookie && typeof shouldAskWelcomeFlowCookie === 'string') {
    return shouldAskWelcomeFlowCookie === 'true';
  }

  return;
}
