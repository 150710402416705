import { Box, CloseButton, Text, ToastProps } from '@chakra-ui/react';
import { CancelIcon, CheckedIcon, InfoIcon, LightningIcon, WarningIcon } from '@udacity/chakra-uds-icons';

export function ToastBody({ variant, title, description, onClose }: ToastProps) {
  return (
    <Box
      alignItems='stretch'
      bg='white'
      border='solid 1px'
      borderColor='gray.500'
      borderRadius='base'
      display='flex'
      justifyContent='space-between'
      shadow='md'
    >
      <Box alignItems='stretch' display='flex'>
        <Box
          flexShrink={0}
          mr='2'
          sx={variant === 'achievement' ? { bgGradient: colorMap.get(variant) } : { bg: colorMap.get(variant) }}
          w='2'
        />
        {iconMap.get(variant)}
        <Box display='flex' flexDirection='column' mx='4' py='5'>
          <Text fontWeight='700'>{title}</Text>
          <Text>{description}</Text>
        </Box>
      </Box>
      <CloseButton mr='3' mt='4' onClick={onClose} />
    </Box>
  );
}

const colorMap = new Map();
colorMap.set('success', 'feedback.positive');
colorMap.set('error', 'feedback.negative');
colorMap.set('warning', 'feedback.warning');
colorMap.set('info', 'blue.500');
colorMap.set('achievement', 'linear(to-b, blue.500, accent.purple)');

const iconMap = new Map();
iconMap.set('success', <CheckedIcon alignSelf='center' color={colorMap.get('success')} h='32px' w='32px' />);
iconMap.set('error', <CancelIcon alignSelf='center' color={colorMap.get('error')} h='32px' w='32px' />);
iconMap.set('warning', <WarningIcon alignSelf='center' color={colorMap.get('warning')} h='32px' w='32px' />);
iconMap.set('info', <InfoIcon alignSelf='center' color={colorMap.get('info')} h='32px' w='32px' />);
iconMap.set('achievement', <LightningIcon alignSelf='center' color='blue.500' h='32px' w='32px' />);
