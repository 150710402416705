import Cookie from 'js-cookie';

import { VIEWER_CURRENCY_COOKIE } from '~/features/cookies/constants';

import { Currency, currencySchema } from '../models/currency';

export function getViewerCurrency(): Currency {
  const viewerCurrency = Cookie.get(VIEWER_CURRENCY_COOKIE);
  return currencySchema.safeParse(viewerCurrency).success ? (viewerCurrency as Currency) : 'USD';
}
