import auth from '@udacity/ureact-hoth';

import { getActiveSubscriptionCookie } from '~/features/cookies/utils/get-active-subscription-cookie';

import {
  getViewerCity,
  getViewerCountryCode,
  getViewerRegionCode,
} from '../../internationalization/utils/get-viewer-geo-codes';
import { isEuCountryCode } from '../../internationalization/utils/is-eu-country-code';

import { getGclid } from './get-gclid';

export function getDefaultAnalyticProperties() {
  const isAuthenticated = auth.isAuthenticated();
  const userActiveSubscription = getActiveSubscriptionCookie();

  return {
    version: '2',
    cta_location:
      window.location.pathname === '/' ? (isAuthenticated ? 'Dashboard' : 'Homepage') : window.location.pathname,
    user_status: isAuthenticated ? 'logged_in' : 'logged_out',
    user_has_active_subscription: userActiveSubscription ? true : false, // userActiveSubscription can be undefined
    site_identifier: 'Next.Sites',
    gclid: getGclid(),
  };
}

export function getGeoAnalyticsProperties() {
  const countryCode = getViewerCountryCode();
  const regionCode = getViewerRegionCode();
  const city = getViewerCity();
  return {
    countryCode,
    regionCode,
    city,
    isEU: countryCode ? isEuCountryCode(countryCode) : undefined,
  };
}
