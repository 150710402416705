import decode from 'jwt-decode';

export const getUserIdFromJwt = (jwt: string): string | undefined => {
  const maybeDecodedUserJwt = decode(jwt);

  if (isDecodedUserJwt(maybeDecodedUserJwt)) {
    return maybeDecodedUserJwt.uid;
  }
};

const isDecodedUserJwt = (value: unknown): value is { uid: string } => {
  return value !== null && typeof value === 'object' && 'uid' in value;
};
