import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import auth from '@udacity/ureact-hoth';

import { getActiveSubscriptionCookie } from '~/features/cookies/utils/get-active-subscription-cookie';
import { getAnonymousIdCookie } from '~/features/cookies/utils/get-anonymous-id-cookie';
import { getShouldAskWelcomeFlowCookie } from '~/features/cookies/utils/get-should-ask-welcome-flow-cookie';
import { getShouldShowWelcomeModalCookie } from '~/features/cookies/utils/get-should-show-welcome-modal-cookie';
import { getTeamLearnerCookie } from '~/features/cookies/utils/get-team-learner-cookie';
import { publicEnv } from '~/features/environment/public';
import { getUserIdFromJwt } from '~/features/user/utils/get-user-id-from-jwt';

const environmentName = publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME;
const loggingEnabled = publicEnv.NEXT_PUBLIC_LOGGING === 'enabled';
const version = publicEnv.NEXT_PUBLIC_BUILD_SHA;

const eventMessagesToIgnore = [
  'web.chtbl.com',
  'https://pagead2.googlesyndication.com',
  'XHR error GET https://api.getblueshift.com/unity.gif',
  'Fetch error post https://sgmt.udacity.com/api/v1/t',
  'Fetch error post https://sgmt.udacity.com/api/v1/i',
  'Fetch error post https://sgmt.udacity.com/api/v1/p',
  'Fetch error POST https://sgmt.udacity.com/api/v1/m',
  'Fetch error POST https://api.segment.io/v1/m',
  '[Fast Refresh]',
  '%cConsent Manager%c',
  '[analytics.js] Failed to load Analytics.js',
  'transcend',
  'Abort Route Change',
];

export function initDatadog() {
  if (!loggingEnabled) return;

  const jwt = auth.getJWT();

  datadogRum.init({
    applicationId: '7fbd1186-86c4-4697-b64b-46250b782d9e',
    clientToken: 'pub09cc3034bf917ec5e98a2e8701672c54',
    site: 'datadoghq.com',
    service: 'udacity-web',
    env: environmentName,
    version: version,
    sessionSampleRate: 20,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    silentMultipleInit: true,
    enableExperimentalFeatures: ['clickmap'],
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogLogs.init({
    clientToken: 'pub09cc3034bf917ec5e98a2e8701672c54',
    site: 'datadoghq.com',
    service: 'udacity-web',
    env: environmentName,
    forwardErrorsToLogs: true,
    silentMultipleInit: true,
    sessionSampleRate: 20,
    version: version,
    forwardConsoleLogs: 'all',
    beforeSend: (event) => {
      if (eventMessagesToIgnore.some((eventMessage) => event.message.includes(eventMessage))) {
        return false;
      }
    },
  });

  if (jwt) {
    const user = {
      id: getUserIdFromJwt(jwt),
    };

    datadogRum.setUser(user);
    datadogLogs.setUser(user);
  }

  const anonymousId = getAnonymousIdCookie();
  const userActiveSubscriptionCookie = getActiveSubscriptionCookie();
  const teamLearnerCookie = getTeamLearnerCookie();
  const shouldAskWelcomeFlowCookie = getShouldAskWelcomeFlowCookie();
  const shouldShowWelcomeModalCookie = getShouldShowWelcomeModalCookie();

  datadogLogs.setGlobalContextProperty('user-has-jwt', Boolean(jwt));
  datadogLogs.setGlobalContextProperty('anonymous-id-cookie', anonymousId);
  datadogLogs.setGlobalContextProperty('user-active-subscription-cookie', userActiveSubscriptionCookie);
  datadogLogs.setGlobalContextProperty('team-learner-cookie', teamLearnerCookie);
  datadogLogs.setGlobalContextProperty('should-ask-welcome-flow-cookie', shouldAskWelcomeFlowCookie);
  datadogLogs.setGlobalContextProperty('should-show-welcome-modal-cookie', shouldShowWelcomeModalCookie);

  datadogRum.startSessionReplayRecording();
}
