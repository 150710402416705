import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { getShouldAskWelcomeFlowCookie } from '~/features/cookies/utils/get-should-ask-welcome-flow-cookie';
import { maybeJwt } from '~/features/user/utils/get-user-id';

export function useWelcomeTrap() {
  const router = useRouter();
  const shouldAskWelcomeFlowCookie = getShouldAskWelcomeFlowCookie();

  useEffect(() => {
    const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
      // If on welcome page, don't redirect
      if (url.includes('/welcome') || url.includes('/checkout')) return;

      // Otherwise, redirect to welcome page
      if (Boolean(maybeJwt) && shouldAskWelcomeFlowCookie) {
        router.push('/welcome');
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, shouldAskWelcomeFlowCookie]);
}
