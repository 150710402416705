import cookie from 'js-cookie';

import { ACTIVE_SUBSCRIPTION_COOKIE } from '~/features/cookies/constants';

export function getActiveSubscriptionCookie(): boolean | undefined {
  const allAccessCookieValue = cookie.get(ACTIVE_SUBSCRIPTION_COOKIE);

  // If the cookie exists, it will be a string of 'true' or 'false'
  if (allAccessCookieValue && typeof allAccessCookieValue === 'string') {
    return allAccessCookieValue === 'true';
  }

  return;
}
