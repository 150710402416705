import Cookie from 'js-cookie';

import { VIEWER_CITY_COOKIE, VIEWER_COUNTRY_COOKIE, VIEWER_REGION_COOKIE } from '~/features/cookies/constants';

export function getViewerCountryCode() {
  return Cookie.get(VIEWER_COUNTRY_COOKIE);
}

export function getViewerRegionCode() {
  return Cookie.get(VIEWER_REGION_COOKIE);
}

export function getViewerCity() {
  return Cookie.get(VIEWER_CITY_COOKIE);
}
