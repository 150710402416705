import { extendTheme } from '@chakra-ui/react';
import { focusStyles, theme as udsTheme } from '@udacity/chakra-uds-theme';

export const theme = extendTheme(udsTheme, {
  styles: {
    global: {
      'html, body, #__next': {
        height: '100%',
        scrollBehavior: 'smooth',
      },
      body: {
        bgColor: 'gray.100',
        overflowX: 'hidden',
      },
      '*::selection': {
        background: 'accent.lime',
        color: 'black',
      },
      strong: {
        fontWeight: '600',
      },
      '.uds-carousel .swiper-scrollbar': {
        top: 'var(--carousel-scrollbar-offset-top, 20px)',
        right: '136px',
      },
      '.uds-carousel--rtl .swiper-scrollbar': {
        right: 'unset',
        left: '136px',
      },
      '.uds-carousel .swiper-wrapper': {
        gap: '16px',
      },
      '.uds-carousel .swiper-slide': {
        margin: '0 !important',
      },
      '.youtube-container': {
        width: '100%',
        height: 'auto',
        aspectRatio: '16 / 9',
      },
      '.legal-text-container .ureact-markdown p': {
        fontSize: '12.8px',
        lineHeight: '24px',
        color: 'black',
      },
      '.legal-text-container .ureact-markdown a': {
        fontSize: '12.8px',
        lineHeight: '24px',
        color: 'blue.500',
      },
      '.PhoneInput': {
        gap: '8px',
      },
      '.PhoneInputCountry': {
        height: '48px',
        alignSelf: 'flex-end',
        padding: '0 12px',
        border: 'solid 1px',
        borderColor: 'gray.600',
        rounded: 'base',
        gap: '12px',
        marginRight: 0,
      },
      '.brow-banner': {
        '*': {
          _focus: focusStyles('white'),
        },
      },
    },
  },
  components: {
    // Credit: https://github.com/chakra-ui/chakra-ui/issues/2893#issuecomment-780143150
    // This is a workaround to create drawers that allow users to interact with the content behind it while the drawer is open.
    Drawer: {
      variants: {
        alwaysOpen: {
          parts: ['dialog, dialogContainer'],
          dialog: {
            pointerEvents: 'auto',
          },
          dialogContainer: {
            pointerEvents: 'none',
          },
        },
        mobile: {
          parts: ['header'],
          header: {
            shadow: 'md',
            zIndex: 1,
            height: '56px',
          },
          closeButton: { position: 'relative', top: 'unset', right: 'unset' },
          footer: {
            shadow: '0px -2px 4px -1px #0B0B0B0F,0px -4px 6px -1px #0B0B0B1A;',
          },
        },
      },
    },
    Heading: {
      sizes: {
        'marketing-6xl': {
          fontFamily: 'heading',
          fontSize: '3.875rem',
          lineHeight: '4rem',
          fontWeight: '400',
        },
        'marketing-5xl': {
          fontFamily: 'heading',
          fontSize: '3rem',
          lineHeight: '3.25rem',
          fontWeight: '400',
        },
        'marketing-4xl': {
          fontFamily: 'heading',
          fontSize: '2.5rem',
          lineHeight: '3rem',
          fontWeight: '400',
        },
        'marketing-3xl': {
          fontFamily: 'heading',
          fontSize: '2rem',
          lineHeight: '2.5rem',
          fontWeight: '400',
        },
        'marketing-2xl': {
          fontFamily: 'heading',
          fontSize: '1.75rem',
          lineHeight: '2.1875rem',
          fontWeight: '400',
        },
        'marketing-xl': {
          fontFamily: 'heading',
          fontSize: '1.5rem',
          lineHeight: '1.875rem',
          fontWeight: '400',
        },
        'marketing-lg': {
          fontFamily: 'heading',
          fontSize: '1.25rem',
          lineHeight: '1.6875rem',
          fontWeight: '400',
        },
        'marketing-md': {
          fontFamily: 'heading',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
        },
      },
    },
    Switch: {
      variants: {
        custom: {
          parts: ['track'],
          track: {
            bg: 'accent.sea-foam.600 !important',
          },
        },
      },
    },
  },
  sizes: {
    ['content-max-width']: '1120px',
    ['content-max-width-lg']: '928px',
    ['content-max-width-md']: '534px',
  },
});
