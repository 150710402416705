// The default locale used by nextjs.
// Used to determine whether localization redirects are needed or to stop additional pages being built if they don't support localization yet.
export const DEFAULT_LANGUAGE = 'default';

export const languages = {
  arSA: {
    id: 'ar-SA',
    title: 'Arabic',
    preferenceCode: 'ar',
    label: 'العربية',
  },
  deDE: {
    id: 'de-DE',
    title: 'German',
    preferenceCode: 'de',
    label: 'Deutsch',
  },
  enUS: {
    id: 'en-US',
    title: 'English',
    preferenceCode: 'en-US',
    label: 'English',
  },
  enAE: {
    id: 'en-AE',
    title: 'English (U.A.E.)',
  },
  enEG: {
    id: 'en-EG',
    title: 'English (Egypt)',
  },
  enIN: {
    id: 'en-IN',
    title: 'English (India)',
  },
  enEU: {
    id: 'en-EU',
    title: 'English (Europe)',
  },
  enSA: {
    id: 'en-SA',
    title: 'English (Saudi Arabia)',
  },
  esES: {
    id: 'es-ES',
    title: 'Spanish',
    preferenceCode: 'es',
    label: 'Español',
  },
  frCA: {
    id: 'fr-CA',
    title: 'French (Canada)',
    preferenceCode: 'fr-CA',
    label: 'Français (Canada)',
  },
  frFR: {
    id: 'fr-FR',
    title: 'French (France)',
    preferenceCode: 'fr',
    label: 'Français',
  },
  ja: {
    id: 'ja',
    title: 'Japanese',
    preferenceCode: 'ja',
    label: '日本語',
  },
  koKR: {
    id: 'ko-KR',
    title: 'Korean',
    preferenceCode: 'ko',
    label: '한국어',
  },
  ptBR: {
    id: 'pt-BR',
    title: 'Portuguese',
    preferenceCode: 'pt-BR',
    label: 'Português',
  },
  ruRU: {
    id: 'ru-RU',
    title: 'Russian',
    preferenceCode: 'ru',
    label: 'Русский',
  },
  trTR: {
    id: 'tr-TR',
    title: 'Turkish',
    preferenceCode: 'tr',
    label: 'Türkçe',
  },
  zhCN: {
    id: 'zh-CN',
    title: 'Chinese',
  },
} as const;

export const languageList = Object.values(languages).map((language) => language);
export const supportedLocaleCodes = Object.values(languages).map((language) => language.id);
export type SupportedLocaleCode = (typeof supportedLocaleCodes)[number];
export const languagePreferenceOptions = (
  Object.values(languages).filter((language) => 'preferenceCode' in language) as any as {
    id: string;
    preferenceCode: string;
    label: string;
  }[]
).map((language) => ({
  label: language.label,
  value: language.preferenceCode,
}));
