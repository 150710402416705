import { z } from 'zod';

export const currencySchema = z.enum([
  'AED',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'EGP',
  'EUR',
  'GBP',
  'INR',
  'NZD',
  'SAR',
  'USD',
  'JPY',
  'KRW',
  'HKD',
  'MAD',
  'QAR',
  'SGD',
  'ZAR',
]);

export type Currency = z.infer<typeof currencySchema>;
