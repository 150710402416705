/**
 *
 * Function stripped from [`graphql-request`](https://github.com/jasonkuhrt/graphql-request/blob/839b61e6aeade79cd5911e90a557ccd5ec36c160/src/index.ts#L663-L668).
 */
export const gql = (chunks: TemplateStringsArray, ...variables: unknown[]): string => {
  return chunks.reduce(
    (acc, chunk, index) => `${acc}${chunk}${index in variables ? String(variables[index]) : ``}`,
    ``
  );
};
