import { z } from 'zod';

export const userPreferencesSchema = z
  .object({
    meta: z.object({
      count: z.number(),
      pagination: z.object({
        limit: z.number(),
        offset: z.number(),
        next_url: z.string(),
        prev_url: z.string(),
      }),
    }),
    objects: z.array(
      z.object({
        created_at: z.string(),
        name: z.string(),
        type: z.string(),
        updated_at: z.string().nullable(),
        user_id: z.string(),
        value: z.string(),
      })
    ),
  })
  .transform((data) => {
    const preferredCurrency = data.objects.find((obj) => obj.name === 'currency')?.value;
    const preferredLanguage = data.objects.find((obj) => obj.name === 'preferred_language')?.value;
    // const receiveMarketing = data.objects.find((obj) => obj.name === 'receive_marketing')?.value;

    return {
      preferredCurrency,
      preferredLanguage,
      // receiveMarketing,
    };
  });

export type UserPreferences = z.infer<typeof userPreferencesSchema>;
export type UserPreferencesResponse = z.input<typeof userPreferencesSchema>;
