import { z } from 'zod';

import { gql } from '~/utils/gql';

export const userHasEnterpriseEnrollmentsSchema = z
  .object({
    data: z.object({
      user: z.object({
        has_enterprise_enrollments: z.boolean().nullable().optional(),
      }),
    }),
  })
  .transform(({ data }) => ({
    hasEnterpriseEnrollments: Boolean(data.user?.has_enterprise_enrollments),
  }));

export type UserHasEnterpriseEnrollments = z.infer<typeof userHasEnterpriseEnrollmentsSchema>;
export type UserHasEnterpriseEnrollmentsResponse = z.input<typeof userHasEnterpriseEnrollmentsSchema>;

export const userHasEnterpriseEnrollmentsQuery = gql`
  query FetchUserHasEnterpriseEnrollments($uid: String!) {
    user(id: $uid) {
      has_enterprise_enrollments
    }
  }
`;
