import { PropsWithChildren, useEffect, useState } from 'react';

import { publicEnv } from '~/features/environment/public';

import { onUnhandledRequest } from './utils';

const mockingEnabled = publicEnv.NEXT_PUBLIC_API_MOCKING === 'enabled';

if (process.env.NEXT_RUNTIME === 'nodejs' && mockingEnabled) {
  const { server } = await import('~/mocks/server');
  server.listen({ onUnhandledRequest });
}

export function WithMocks({ children }: PropsWithChildren) {
  const [shouldRender, setShouldRender] = useState(!mockingEnabled);

  useEffect(() => {
    async function setupClientMocks() {
      const { initClientMocks } = await import('./utils');
      await initClientMocks();
      setShouldRender(true);
    }

    if (mockingEnabled) {
      setupClientMocks();
    }
  }, []);

  return shouldRender ? <>{children}</> : null;
}
